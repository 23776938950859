<template>
  <el-main>
    <div class="user-info">
      <div class="title">
        <div style="display: flex; align-items: center">1、用户信息</div>
      </div>
      <div class="info">
        <div class="item">
          分销员信息：
          <el-image :src="info.avatar"></el-image>
          {{ info.nickname }}
        </div>
        <div class="item">
          手机号码：
          {{ info.mobile }}
        </div>
        <div class="item">
          注册时间：
          {{ getDateformat(info.create_time) }}
        </div>
        <div class="item">
          所属地区：
          {{ info.area }}
        </div>
      </div>
    </div>
    <div class="achievement">
      <div class="title">2、回收信息</div>
      <el-table :data="recoveryInfo" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="total_order_amount" label="累计推广订单金额" align="center"></el-table-column>
        <el-table-column prop="total_order_count" label="累计推广订单数" align="center"></el-table-column>
        <el-table-column prop="total_amount" label="累计推广奖励" align="center"></el-table-column>
        <el-table-column prop="total_user_count" label="累计推广用户数" align="center"></el-table-column>
      </el-table>
    </div>
    <div class="lockingUser">
      <div class="title">3、推广用户列表</div>
      <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <el-table-column prop="nickname" label="用户信息" align="center">
          <template v-slot="{ row }">
            <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
          </template>
        </el-table-column>
        <el-table-column prop="area" label="所属区域" align="center"></el-table-column>
        <el-table-column prop="total_order_amount" label="累计订单金额" align="center"></el-table-column>
        <el-table-column prop="total_order_count" label="累计订单数" align="center"></el-table-column>
        <el-table-column prop="energy_value" label="累计公益值" align="center"></el-table-column>
        <el-table-column prop="locking_time" label="绑定时间" align="center">
          <template v-slot="{ row }">{{ getDateformat(row.bind_time) }}</template>
        </el-table-column>
      </el-table>
      <Paging v-if="total_number > 5" :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    </div>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import userInfo from '@/components/userInfo';
export default {
  components: {
    Paging,
    userInfo,
  },
  data() {
    return {
      page: 1,
      rows: 5,
      total_number: 0,
      info: {},
      list: [],
      recoveryInfo: [],
    };
  },

  created() {
    let id = this.$route.query.id;
    if (id) {
      this.info = JSON.parse(this.$route.query.info);
      this.recoveryInfo = [
        {
          total_order_amount: this.info.total_order_amount,
          total_order_count: this.info.total_order_count,
          total_amount: this.info.total_amount,
          total_user_count: this.info.total_user_count,  
        },
      ];
      this.getUserList();
    }
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getUserList();
    },
    getUserList() {
      this.$axios
        .post(this.$api.O2O.inviteUserList, {
          page: this.page,
          rows: this.rows,
          id: this.info.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  font-size: 14px;
  .title {
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .level {
      margin-left: 20px;
      color: #409eff;
      padding: 5px 15px;
      border: 1px solid #409eff;
    }
  }
  .info {
    display: flex;
    padding-left: 40px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .item {
      display: flex;
      align-items: center;
      margin-right: 50px;
      margin-bottom: 10px;
      .el-image {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }
  }
  .el-table {
    margin-bottom: 20px;
  }
}
</style>